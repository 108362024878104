@import "src/assets/variables";
.logo {
  img {
    height: 3rem;
  }
  @media (min-width: $medium-size) {
    img {
      height: 5rem;
      padding: .5rem 0;
    }
  }
}