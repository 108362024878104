$primary-color: #4c4f54 !default;
$secondary-color: #9A3324 !default;
$primary-background-color: #ebebe5 !default;
$secondary-background-color: #f2f1ec !default;
$tertiary-background-color: #9E5330 !default;


$section-heading-color: $secondary-color !default;
$main-font: 'IBM Plex Serif', serif !default;
$heading-font: 'Fjalla One', sans-serif !default;
$nav-font: $heading-font !default;
$italic-font: 'Yellowtail', cursive !default;
$button-font: $heading-font !default;
$button-color: $primary-background-color !default;
$button-background-color: $primary-background-color !default;
$button-font: $main-font !default;

$button-primary-background-color: $tertiary-background-color !default;
$button-secondary-background-color: $tertiary-background-color !default;
$button-tertiary-background-color: $primary-background-color !default;

$search-unit-header-background-color: $secondary-background-color !default;
$floorplan-featured-color: $primary-color !default;
$floorplan-hover-color: $primary-background-color !default;
$floorplan-hero-featured-color: $primary-color !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
