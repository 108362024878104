@import "src/assets/variables";

.section-amenities {
  @media (min-width: $medium-size) {
    ul {
      column-count: 1;
    }
    .section-amenities-content {
      display: flex;
    }
  }
}
