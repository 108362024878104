@import "src/assets/variables";

.section-hero {
  text-align: center;
  background: url(./Hero-background-mobile.png) center/cover;
  padding: 0;
  color: $primary-color;
  position: relative;

  video {
    position: relative;
    width: 100vw;
  }

  .hero-content {
    padding: 2rem 1rem;

    h2 {
      color: $primary-color;
      margin: .5rem;
    }

    p {
      color: $secondary-color;
    }

    .btn {

      padding: .5rem;
      margin: .5rem;

    }
  }

  @media (min-width: $medium-size) {

    background: url(./Hero-background.png) right/cover;
    display: flex;
    height: 32.4vw;
    max-height: 28rem;
    justify-content: space-between;
    align-items: center;

    .btn {
      white-space: normal;
    }

    video {
      width: 58vw;
      max-width: 50rem;
    }
  }
  @media (min-width: $large-size) {
    h2 {
      font-size: 2rem;
    }
  }
}

