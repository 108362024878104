@import "src/assets/variables";

.section-action {
  text-align: center;
  padding: 1rem;

  .action-content {
    a {
      color: $secondary-color;
    }
    .btn {
      white-space: normal;
    }
  }
}

@media (min-width: $medium-size) {
  .section-action {
    .container {
      display: flex;
      text-align: left;
      align-items: center;

      .action {
        display: flex;
        align-items: center;

        .action-content {
          padding: 0 1.5rem;

          h3 {
            text-align: left;
            margin-bottom: 0;
          }

          .btn {
            margin-left: 3rem;
            font-size: 1rem;

          }
        }
      }
    }
  }
}