@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400,700|Yellowtail|Fjalla+One&display=swap');
@import "~@renaissancerentals/renaissance-component/lib/assets/App";


html {
  background: url("./paper.jpg");
}

.glasses-background {
  background-image: url(./glasses-background.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
}

main {
  .heading {
    border: none;

    .emphasized {
      background: none;
      font-family: $heading-font;
      text-transform: uppercase;
    }
  }

  .sort-filters {
    .muncher-button--medium {
      color: $primary-color;
    }

    .muncher-button--active {
      color: $primary-background-color;
    }
  }

  .section-application {
    .application-heading {
      .muncher-button {
        color: $primary-color;
        font-family: $main-font;
        letter-spacing: normal;
      }
    }

  }

  .section-property-location {
    h3 {
      color: $secondary-color;
      font-size: 1.3rem;
    }

    p {
      font-size: .8rem;

      i {
        font-family: $main-font;
        font-size: .8rem;
      }
    }

    a {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }

  }

  .muncher-badge {
    background-color: $secondary-background-color;

    p {
      color: $primary-color;
    }

    .muncher-button {
      p {
        color: $primary-background-color;
      }
    }

    .selected {
      color: $primary-color;
    }
  }

  .section-card {
    color: $primary-color;

    .card-header {
      //background-color: $primary-color;
    }

    .muncher-button--tertiary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }

    i {
      font-family: $serif-font;
    }
  }

  .floorplan--featured {
    .muncher-button {
      color: $primary-color;
    }
  }

  .floorplan-column-right {
    .muncher-button--tertiary {
      background-color: $secondary-color;
    }

    .muncher-button--primary {
      background-color: $primary-color;
    }
  }

  .muncher-dropdown--content {
    .muncher-button--secondary {
      background-color: $secondary-background-color;
      color: $primary-color;
    }
  }
  .section-card .card-body--featured{
    background: $tertiary-background-color;
  }
}

.hamburger {
  cursor: pointer;
  padding: .5rem;
  color: $secondary-color;
}
