@import 'src/assets/variables';

.custom-feature {
  padding: 1rem;
  text-align: center;
  background: $tertiary-background-color;
  color: $primary-background-color;

  .custom-feature--detail {
    h2 {
      color: $primary-background-color;
      text-transform: none;
    }
    i{
      font-family: $heading-font;
      color: $primary-background-color;
    }
  }
}
